@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
/* Override base SVG style to work with Ant Design */
svg {
  vertical-align: initial;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dashboard-status-table {
  height: 139px;
  width: calc(100% - 8px);
  padding: 3px 3px;
}
.table-standard-border {
  font-size: 8pt;
  height: max-content;
  width: 324px;
}
.table-standard-border td {
  border-color: rgba(181, 199, 212, 0.4);
  border-width: 1px;
  border-style: solid;
}
.dashboard-status-table td {
  border-color: rgba(181, 199, 212, 0.4);
  border-width: 1px;
  border-style: solid;
}
.div_title_header {
  text-align: center;
  color: black;
  font-size: 13px;
  height: 30px;
  padding-top: 3px;
}
.img_tilte {
  width: 25px;
  height: 25px;
}
.vaild_err {
  text-align: center;
  color: rgb(188, 188, 188);
  font: italic 17px arial, serif;
  padding-top: 20%;
}
.task-alert span {
  z-index: 10;
  padding: 12px 25px;
  height: auto;
  width: auto;
  display: inline;
  position: absolute;
  color: #111;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  line-height: 19px;
  border: 1px solid #ccc;
}

/* New */

#dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 10px;
  min-width: 1287px;
  height: inherit;
  overflow-x: hidden;
  display: block;
  width: auto;
}
#dashboard-container .dashboard-tile-div {
  padding-right: 10px;
  display: block;
  margin-bottom: 7px;
  margin-top: 5px;
  text-decoration: none;
  position: relative;
  z-index: 20;
  float: left;
  color: white;
  font-size: 8pt;
  text-align: center;
  white-space: normal;
  height: 370px;
}
#dashboard-container .canvas-container-class {
  height: 161px;
  width: 292px;
  text-align: left;
}
#dashboard-container .normal-size-tile {
  width: 310px;
}

#dashboard-container .double-size-tile {
  width: 630px;
}
.dashboard-tile-table-class {
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #ddd;
  border-top: 3px solid #ffa500;
  border-spacing: 0px;
  cursor: pointer;
  width: 100%;
  transition: 0.4s;
  height: 365px;
  overflow: auto;
}

.dashboard-tile-table-class:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background: white;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.modal-sticky-header-footer .ant-modal-header {
  position: sticky;
  top: 0;
  z-index: 4;
}
.modal-sticky-header-footer .ant-modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 4;
  background-color: #fff;
}

.form-add-test h2 {
  font-weight: bold;
}

.before-form-item > div  > label::before {
  content: " *";
  color: red;
}

.padding-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.input-dropdown-search {
  cursor: pointer;
  margin: 2px;
  width: 100%;
}
.input-dropdown-search:hover {
  background: #f2f4f5;
}
.hover-zoom {
  position: fixed;
  height: 300px;
  max-height: fit-content !important;
  z-index: 9999;
}