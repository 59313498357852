.loading {
  left: 50%;
  top: 100px;
  position: relative;
}

#doc-app {
  margin: 0 auto;
  padding: 10px;
  .actions {
    display: flex;
    .action-items {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      display: flex;
      gap: 15px;
      text-align: center;
      justify-items: center;
      border: 1px solid rgba(59, 130, 246, 0.5);
      padding: 5px 15px;
      border-radius: 3px;
      .ant-image-disabled {
        opacity: .5;
        user-select: none;
        &:hover {
          cursor: not-allowed;
        }
      }
      .ant-image:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    margin-top: 30px;
    min-height: 70vh;
    .doc-tree {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      .tree {
        margin-top: 5px;
      }
    }

    .properties {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      padding: 10px;
      .document-view {
        .links {
          display: flex;
          gap: 20px;
          flex-direction: row;
        }
        .label {
          padding: 0 10px;
          width: 220px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
  }

  .ant-tabs-tab {
    width: 150px;
  }
}

.ant-select {
  width: 100% !important;
}

.ant-picker {
  width: 100% !important;
}

.upload-document-btn {
  border-radius: 30px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
}

textarea {
  resize: none;
}

.ant-tabs-nav-list .ant-tabs-tab {
  font-weight: bold;
}
